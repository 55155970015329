body {
  font-family: "montserrat" !important;
}

.App {
  text-align: center;
  height: 100vh;
  /* padding: 0 2.6em; */
}

head {
  background-color: red;
}

a {
  text-decoration: none !important;
}
/* body {
  margin: 0;
  padding: 0;
  background-color: red;
}

#root{
  max-height: 100vh;
  margin: 0;
  padding: 0;
} */
.col-navbar-right.col-6 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0%;
  overflow: visible;
}

.navbarPerso.row {
  padding: 0em 1em;
  min-height: 5vh;
  position: fixed;
  z-index: 9999 !important;
  overflow: visible;
  width: 100%;
}

.mainContent {
  /* min-height: 130vh; */
  background: #ffffff;
  padding: 0 2em;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.panel-left {
  /* box-shadow: 1px 1px 7px #80808036; */
  height: 100vh;
  padding: 1em 0;
  width: 100%;
  display: grid;
  grid-template-rows: 50px auto auto; /* 1fr pour le contenu, auto pour les boutons */
  height: 100%;
}
.panel-left-2 {
  display: grid;
  align-content: stretch;
  height: 100%;
  justify-content: center;
}
.Row_Content.row {
  padding-right: 0em;
}
img#logo {
  width: 40%;
}

.grpElemSlide {
  margin-top: 5%;
  font-size: 1.1em;
  text-align: left;
  padding-left: 2em;
  padding-right: 0em;
}
.grpElemSlide-settings {
  margin-top: 2em;
  font-size: 1.1em;
  text-align: left;
  padding-left: 0em;
  padding-right: 0em;
}
.logo-container {
  display: flex; /* Pour aligner l'image et le texte côte à côte */
  align-items: center; /* Centrer verticalement */
  /*background: #346840;  Couleur de fond */
  padding: 8px 16px; /* Espace autour du contenu */
  padding-left: 3em;
}

.logo-image {
  /* Si vous voulez une taille spécifique pour le logo SVG */
  width: 100px; /* ou la taille que vous voulez */
  background-color: #f7eede;
  justify-content: center;
  align-items: center;
  border-radius: 9%; /* Arrondir les coins pour faire un cercle ou une forme ovale */
  width: 37px; /* Largeur du logo, ajustez selon vos besoins */
  height: 37px; /* Hauteur du logo, ajustez selon vos besoins */
  padding: 5px 11px 5px 11px;
  margin-right: 20px; /* Espace entre le logo et le texte */
}

img.button-icon-image {
  width: auto;
  height: 80%;
  flex-shrink: 0; /* Remplir l'espace du conteneur */
}
button.button-icon {
  background-color: #656565;
  width: 50px;
  height: 50px;
  border-radius: 6px;
  box-shadow: -2px 4px 7px rgba(54, 86, 68, 0.4);
  margin: 10px;
  padding: 6px;
  border: none;
}

.logo-text {
  color: #f7eede;
  font-family: Montserrat;
  font-size: 23px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
}
img.logo-svg {
  width: 15px;
  height: auto;
  flex-shrink: 0; /* Remplir l'espace du conteneur */
}

.elemSlide {
  background-color: rgba(52, 104, 64, 0.2);
  color: white; /* Couleur du texte */
  padding: 12px 20px; /* Espace intérieur */
  margin: 0.7em 0; /* Espace extérieur vertical */
  border-top-right-radius: 0; /* Coin supérieur droit sans arrondi */
  border-bottom-right-radius: 0; /* Coin inférieur droit sans arrondi */
  border-top-left-radius: 20px; /* Coin supérieur gauche arrondi */
  border-bottom-left-radius: 20px; /* Coin inférieur gauche arrondi */
  width: 100%;
  cursor: pointer; /* Changement de curseur au survol */
  transition: background-color 0.3s, box-shadow 0.3s;
  font-weight: 400;
  font-size: 0.9em;
}
.elemSlide:hover {
  transition: 0.4s;
  background-color: rgba(255, 255, 255, 0.5);
  color: #365644;
  font-weight: 500;
}
.elemSlide.select {
  background: #ffffff;
  font-weight: 600;
  color: #365644;
}
/* .dashboard.row {
    height: 100vh;
    background: linear-gradient(#d9e8ed 50%, #f3f5fa 50%);
    padding: 3em 1.3em;
} */
.contenerLogo {
  margin: 1em 0;
  margin-bottom: 3em;
}

.sidebarBloc {
  padding-right: 0px !important;
  width: 22%;
  z-index: 1;
}
.sidebarBloc2 {
  padding-right: 0px !important;
  width: 11%;
  z-index: 1;
}
.deco_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; /* Centrer le contenu horizontalement */
  height: 100%; /* La hauteur doit être définie pour que cela fonctionne */
  margin-top: 5%;
}
.deco_button-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Répartir l'espace verticalement */
  align-items: center; /* Centrer le contenu horizontalement */
  height: 100%; /* La hauteur doit être définie pour que cela fonctionne */
}
.link-style {
  text-decoration: none; /* Enlevez le soulignement */
  color: inherit; /* Gardez la couleur du texte parent */
}

.icon-container {
  display: flex;
  flex-direction: column;
  background-color: #dcdcdc;
  width: 60px; /* ou la taille souhaitée */
  height: 60px; /* ou la taille souhaitée */
  border-radius: 10px; /* pour les coins arrondis */
  margin-bottom: 2em;
  justify-content: center;
  align-items: center;
}

.icon-button {
  width: 50px; /* ou la taille souhaitée */
  height: 50px; /* ou la taille souhaitée */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease; /* pour une transition douce */
  cursor: pointer;
  pointer-events: auto; /* S'assure que l'élément est cliquable */
}
button.icon-button-3.btn.btn-primary {
  width: 50px; /* ou la taille souhaitée */
  height: 50px; /* ou la taille souhaitée */
  display: flex;
  align-items: center;
  background-color: #dcdcdc;
  color: #365644;
  justify-content: center;
  transition: background-color 0.3s ease; /* pour une transition douce */
  cursor: pointer;
  pointer-events: auto; /* S'assure que l'élément est cliquable */
}

.icon-container.select {
  background-color: #f7eede; /* ou tout autre couleur pour l'état actif */
}

/* SVG Styles */
.icon-button svg {
  fill: currentColor; /* Icon color */
}

.row {
  margin-right: 0 !important;
  padding-left: 0;
  margin-top: 0 !important;
  --bs-gutter-x: 0 !important;
}
.row > * {
  padding-right: 0;
  padding-left: 0;
  margin-top: 0;
}

.nameDash.col {
  text-align: left;
}

.mainBloc {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

div.left {
  align-items: center;
  justify-content: left;
  text-align: left;
}

.form-label {
  font-size: 20px; /* Taille normale pour 'Identifiant' */
  margin-left: 0; /* Pas d'espace supplémentaire après le label */
}
.form-label_2 {
  font-size: 1rem; /* Taille normale pour 'Identifiant' */
  margin-bottom: 0; /* Pas d'espace supplémentaire après le label */
}

.form-label.text-muted.small {
  font-size: 0.75rem; /* Plus petit pour 'votre adresse email' */
  color: #6c757d; /* Couleur Bootstrap pour les éléments 'muted' */
}
.email-container {
  display: flex;
  flex-direction: column;
}

h1.hdPage {
  font-size: 1.6em;
  margin-left: 1.1em;
  color: #284e67;
  font-weight: 600;
  margin-bottom: 0;
}

.sidebarBloc.col-3 {
  position: fixed;
  width: 22% !important;
  height: 100vh;
  background-color: #90b878;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7em;
  min-width: 300px;
}
.sidebarBloc2.col-3 {
  position: fixed;
  width: 6% !important;
  height: 100vh;
  background-color: #838383;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7em;
}
.row > * {
  flex-shrink: 0;
  /*width: 100%;*/
  overflow: hidden;
}
.mainBloc.col-9 {
  margin-left: 22%;
  margin-top: 6em;
  width: 78%;
}
.mainBloc.col-10 {
  margin-left: 6%;
  margin-top: 6em;
  width: 78%;
}
.col-9 {
  flex: 0 0 auto;
  width: auto;
}

.user.col {
  text-align: right;
}

.bandeauBloc {
  height: 4%;
}
.contentBloc {
  height: 86%;
  margin-bottom: 7%;
}

.blocChiffre {
  background: white;
  border-radius: 4px;
  box-shadow: 1px 1px 5px #8080801c;
  height: auto;
  text-align: left;
  padding: 1.8em;
  padding-bottom: 1em;
  padding-right: 0;
  /* padding-top: 1.9em; */
}

.fullGraph {
  height: 99.7%;
  background: white;
  border-radius: 4px;
  box-shadow: 1px 1px 5px #8080801c;
  padding: 1em 1.2em;
}

.blocChiffre.middle {
  margin: 8% 0;
}

.typeChiffre {
  font-size: 1.4em;
  color: #586065;
  font-weight: bold;
}

.contentChiffre {
  font-size: 1.4em;
  line-height: 1.8em;
  font-weight: 300;
}
.contentChiffre span {
  font-size: 2.9em;
  font-weight: bold;
  line-height: 1.5em;
}

.contentChiffre .blue {
  color: #107ec1;
}
.contentChiffre .yellow {
  color: #b9ae29;
}
.contentChiffre .green {
  color: #1e6e0b;
}

#root {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.sliderPage {
  background: #284e67;
  /* background: #1E4156; */
  height: 100vh;
  padding: 6%;
  margin-bottom: 0 !important;
}

img#logoSlider {
  width: 20%;
}

/* :root {
  --main-color: rgb(104, 117, 217);
  --main-color-hover: rgb(104, 117, 217, 0.2);
  --track-color: #ddd;
} */

.customSlider-mark {
  cursor: pointer;
  top: 6px;
  width: 1.5px;
  height: 8px;
  background-color: var(--mark-color);
}
.customSlider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background: var(--main-color);
  /* shape of the thumb: circle */
  width: 40px;
  height: 40px;
  border-radius: 100%;
  /* remove default outline when selected */
  outline: none;
}

.customSlider-thumb:hover {
  box-shadow: 0 0 0 8px var(--main-color-hover);
}

.customSlider {
  /* max width of your slider */
  max-width: 100%;
  /* Optional: Only If you want to center your slider in the page */
  margin: auto;
}

.customSlider-track {
  /* Top value to align your track to the center of your thumb */
  top: 15px;
  /* thickness of the track */
  height: 15px;
  border-radius: 20px;
  /* default color of your track */
  background: var(--track-color);
}

.customSlider-track.customSlider-track-0 {
  /* color of the track before the thumb */
  background: var(--main-color);
}

.elemSlide.inactive {
  background: #f2f2f2;
  color: lightgray;
  cursor: initial;
}

:root {
  /* --main-color: rgb(104, 117, 217); */
  --main-color: #c66317;
  --main-color-hover: rgb(104, 117, 217, 0.2);
  --track-color: #ddd;
}

.labelNb.row {
  color: white;
  font-size: 2em;
  margin-bottom: 1em;
}

.blocNb {
  /* height: 30%; */
  margin: 6em 0;
}

.nbSlider.col-6 {
  /* margin-top: -0.6em; */
  font-size: 3.2em;
  line-height: 1em;
  text-align: right;
  /* height: 1em; */
  margin-top: -0.6em;
}

.labelType {
  color: white;
  font-size: 2em;
  margin-bottom: 1em;
}

button.btnType.btn {
  width: 100%;
  outline: none;
  border: 0;
  font-size: 1.4em;
  background-color: #9b9b9b;
}
button.btnType.btn:active {
  background-color: #6d6a6a;
}

.btnType.btn.selected {
  background-color: #c66317;
}

button.btnCalculer.btn.btn-primary {
  margin: 3.3em 0 0;
  font-size: 2em;
  width: 30.3%;
  background: white;
  border: 0;
  color: black;
}

button.btnCalculer.btn.btn-primary:active {
  background: gray;
}

.contenerSaas {
  padding: 0em;
  min-height: 80vh;
  background: white;
  border-radius: 9px;
}

button.bouton_annuler.btn.btn-primary {
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 1em;
  border: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #e7e7e7;
  color: #346840; /* Texte blanc */
  font-family: Montserrat;
}

.bouton_annuler:hover {
  background-color: #e6e6e6; /* Légèrement plus foncé lors du survol */
}

.bouton_valider {
  padding: 10px 20px; /* Ajustez le padding selon vos besoins */
  margin-left: 1em;
  border-radius: 5px; /* Bordures arrondies */
  border: none; /* Pas de bordure */
  outline: none; /* Enlève l'outline par défaut */
  font-size: 16px; /* Taille de police */
  cursor: pointer; /* Curseur en forme de main lors du survol */
  transition: background-color 0.3s;
  background-color: #346840; /* Couleur de fond verte */
  color: #f7eede; /* Texte blanc */
  font-family: Montserrat;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
button.bouton_hotel_choice.btn.btn-primary {
  padding: 10px 20px;
  margin-left: 1em;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #365644;
  color: #ffffff;
  font-family: Montserrat;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  font-weight: 700;
}
button.bouton_hotel_choice.btn.btn-primary:hover {
  transition: 0.5s;
  background-color: #365644cd;
}

.contenerAction {
  border-top: 2px solid lightgrey;
  margin-top: 0.5em;
  padding: 0.6em 2em;
  display: flex;
  /* justify-items: right; */
  justify-content: center;
}

input.btnLwe {
  width: 8em;
  margin-left: 1em;
}
input.btnLwe.btn.btn-primary.btnCancel {
  background: lightslategray;
  border-color: lightslategray;
}

input.btnLwe.btn.btn-primary.btnSave {
  background: #5cb048;
  border-color: #5cb048;
}

input.btnLwe.btn.btn-primary.btnSave:hover {
  background: #499436;
}
input.btnLwe.btn.btn-primary.btnCancel:hover {
  background: gray;
}

.titleContener {
  text-align: left;
  padding: 0.7em 1.5em;
  /* background: #d3d9c8; */
  font-size: 1.2em;
  border-bottom: 3px solid lightgrey;
  border-radius: 9px 9px 0 0;
  font-weight: 500;
}

.mainContener {
  min-height: 50vh;
}

.infoHotel.col-8 {
  text-align: left;
}

.right.col-6 {
  justify-content: right;
  display: flex;
}
.titre_param.col-6 {
  width: fit-content;
}
.flexCenter {
  display: flex;
  align-items: center;
}

.right.navBarBtn.col-6 {
  padding-right: 1em;
}

.hotelName {
  font-weight: 600;
  font-size: 1.1em;
}

.flex-center {
  align-items: center;
  justify-content: center;
  display: flex;
}
.flex-right {
  align-items: center;
  justify-content: right;
  display: flex;
}
svg.svg-inline--fa.fa-star.fa-w-18.inactive {
  color: lightgray;
}
svg.svg-inline--fa.fa-star.fa-w-18.active {
  color: #d48509;
}
svg.svg-inline--fa.fa-star.fa-w-18 {
  cursor: pointer;
}
svg.svg-inline--fa.fa-star.fa-w-18:hover {
  color: #9d6815;
  transition: 300ms;
}

.starNot {
  font-size: 1.5em;
}

.formLwe {
  margin-left: 1.5em;
}

*,
::after,
::before {
  box-sizing: border-box;
  border: none !important;
}
.formLwe hr {
  display: none;
}
.form-input.col-8 {
  font-size: 18px; /* Taille de police appropriée */
  padding: 10px; /* Espace interne autour du texte */
  border: 2px solid #346840 !important; /* Bordure verte solide */
  border-radius: 10px; /* Bordures arrondies */
  outline: none; /* Supprime l'outline par défaut lors de la sélection */
  box-sizing: border-box; /* S'assure que le padding et la bordure sont inclus dans la largeur */
}
.form-input {
  margin-bottom: 1em;
  font-size: 15px; /* Taille de police appropriée */
  padding: 10px; /* Espace interne autour du texte */
  border: 2px solid #346840 !important; /* Bordure verte solide */
  border-radius: 10px; /* Bordures arrondies */
  outline: none; /* Supprime l'outline par défaut lors de la sélection */
  box-sizing: border-box; /* S'assure que le padding et la bordure sont inclus dans la largeur */
}
.input-email:focus {
  font-weight: 800; /* Changement de couleur de la bordure lors de la sélection */
}
.statusForm {
  background: #ff5722;
  padding: 0.5em 2em;
  margin: 1em;
  border-radius: 9px;
  text-align: left;
}
.border_bottom {
  border-bottom: #365644 solid 1px !important;
  margin-left: 2em;
}
.statusForm.sucess {
  background-color: #90b878;
}
.oneFormInput.row {
  padding: 1em 0em;
}

input.lweInput {
  width: 100%;
  background-color: rgb(0, 0, 0, 0);
  border: 0;
  outline: 0;
  padding-left: 5px;
  transition-duration: 300ms;
}

input.lweInput:focus {
  border-bottom: #284e67 solid 5px;
}

.grayText {
  color: gray;
}

.content {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.content.visible {
  opacity: 1;
  visibility: visible;
}
/* UserDropdown.css */
.user-dropdown {
  position: relative; /* Cette ligne est nécessaire pour le positionnement du menu déroulant */
  display: flex;
  align-items: center;
  background-color: #e7e7e7;
  border-radius: 2em;
  padding: 10px 1.4em;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
  user-select: none;
}

.avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.username {
  margin-right: 10px;
  font-weight: 600;
  font-size: 0.9em;
}

.dropdown-icon {
  user-select: none;
  color: white;
}

ul.dropdown-menu-2 {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  margin-top: 3px;
  box-shadow: 0px 4px 5px 0px rgb(192 192 192 / 20%);
  z-index: 9900;
  border-radius: 5px;
  font-size: 0.9em;
  padding: 2px;
  width: 200px;
  display: block !important;
  /* width: fit-content; */
  background: #f6f6f6;
}

.dropdown-item {
  padding: 0.5em 0.3em !important;
  cursor: pointer;
  width: fit-content;
  color: black;
}

a.dropdown-text {
  color: black;
}
a.dropdown-text:hover {
  color: black;
}

.dropdown-item:hover {
  background-color: #f2f2f2;
}

button.navbar-icon-button {
  background-color: rgb(0, 0, 0, 0);
  border: none;
  width: 45px; /* Ajustez en fonction de la taille de vos icônes */
  height: 45px;
  margin: 10px;
  padding: 0;
}
button.navbar-icon-button-end.btn.btn-primary {
  background-color: #787878; /* Darker background for distinction */
  border: none;
  width: 35px; /* Smaller width */
  height: 35px; /* Smaller height */
  margin-right: -10px; /* Negative margin to make it protrude */
  position: relative; /* To allow z-index to work */
  align-self: center; /* Align button vertically */
  align-items: center;
  justify-content: center;
  z-index: 999999; /* To ensure it's above other elements */
}
img.navbar-icon-end {
  filter: brightness(0) saturate(100%) invert(89%) sepia(26%) saturate(121%)
    hue-rotate(352deg) brightness(104%) contrast(94%);
  margin-left: -30%;
  margin-top: -15%;
  /* This filter is an example; adjust the values to match your desired color */
}
home-page {
  margin-top: 25%;
  display: flex;
  justify-content: center;
  /* This filter is an example; adjust the values to match your desired color */
}
button.navbar-icon-button-inactive {
  background-color: rgb(0, 0, 0, 0);
  border: none;
  width: 45px; /* Ajustez en fonction de la taille de vos icônes */
  height: 45px;
  margin: 10px;
  padding: 0;
}
.End-button {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10%;
}
button.navbar-icon-button:hover {
  background-color: rgb(0, 0, 0, 0);
  border: none;
  width: 45px; /* Ajustez en fonction de la taille de vos icônes */
  height: 45px;
  margin: 10px;
  padding: 0;
}
button.navbar-icon-button-active {
  background-color: rgb(0, 0, 0, 0);
  border: none;
  width: 45px; /* Ajustez en fonction de la taille de vos icônes */
  height: 45px;
  margin: 10px;
  padding: 0;
}
button.button_valid_date {
  background-color: #90b878;
  color: #f7eede;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  border-radius: 10px;
  font-weight: 500;
  padding: 5px 20px;
  margin-left: 1em;
  border: none;
}

.navbar-hotel {
  display: flex;
  align-items: center;
  background-color: #365644; /* Utilisez la couleur de votre choix */
  box-shadow: 0px 2px 15px 2px rgba(54, 86, 68, 0.4);
  border-radius: 40px; /* Ajustez en fonction de la courbure que vous souhaitez */
  padding-top: 5px;
  padding-left: 1em;
  padding-bottom: 5px;
  margin: 1em;
  color: white;
  width: 80%;
  min-width: 480px;
}
.navbar-hotel-2 {
  display: flex;
  align-items: center;
  background-color: #365644; /* Utilisez la couleur de votre choix */
  box-shadow: 0px 2px 15px 2px rgba(54, 86, 68, 0.4);
  border-radius: 40px; /* Ajustez en fonction de la courbure que vous souhaitez */
  padding: 0.5em 1em;
  margin: 1em;
  color: white;
  width: 80%;
}

.logo-section-navbarhotel {
  display: flex;
  align-items: center;
  flex-grow: 1; /* Fait en sorte que la section du logo occupe l'espace disponible */
}

img.navbar-logo {
  width: 50px; /* Ajustez en fonction de la taille de votre logo */
  height: 50px; /* Ajustez en fonction de la taille de votre logo */
  margin-right: 10px;
  border-radius: 50%;
}
img.navbar-logo-2 {
  width: 40px; /* Ajustez en fonction de la taille de votre logo */
  height: 40px; /* Ajustez en fonction de la taille de votre logo */
  margin-right: 10px;
  border-radius: 50%;
  margin-left: -5px;
}

.hotel-name {
  font-weight: 600;
  font-size: 1.2rem;
  margin-left: 1em;
}

.icon-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.navbar-icon {
  width: 45px; /* Ajustez en fonction de la taille de vos icônes */
  height: 45px; /* Ajustez en fonction de la taille de vos icônes */
  /*margin: 0 10px; /* Ajustez l'espacement entre les icônes */
  background-color: #dedede;
  padding: 10px;
  border-radius: 10%;
}

.navbar-icon:hover {
  width: 45px; /* Ajustez en fonction de la taille de vos icônes */
  height: 45px; /* Ajustez en fonction de la taille de vos icônes */
  /*margin: 0 10px; /* Ajustez l'espacement entre les icônes */
  background-color: #f7eede;
  padding: 10px;
  border-radius: 10%;
  outline: solid;
  outline-color: #90b878;
}
.navbar-icon:active {
  width: 45px; /* Ajustez en fonction de la taille de vos icônes */
  height: 45px; /* Ajustez en fonction de la taille de vos icônes */
  /*margin: 0 10px; /* Ajustez l'espacement entre les icônes */
  background-color: #f7eede;
  padding: 10px;
  border-radius: 10%;
  outline: solid;
  outline-color: #90b878;
}

.navbar-icon-active {
  width: 45px; /* Ajustez en fonction de la taille de vos icônes */
  height: 45px; /* Ajustez en fonction de la taille de vos icônes */
  /*margin: 0 10px; /* Ajustez l'espacement entre les icônes */
  background-color: #f7eede;
  padding: 10px;
  border-radius: 10%;
  outline: solid;
  outline-color: #90b878;
}
.h1-page {
  color: #365644;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center; /* Centrage vertical */
  justify-content: flex-start;
  margin-top: 1em;
  padding-left: 20px;
}
.h3-page {
  color: #365644;
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  line-height: normal;
  display: flex;
  align-items: center; /* Centrage vertical */
  justify-content: flex-start;
  margin-top: 1em;
  padding-left: 20px;
}

.Room_arrow {
  font-size: 1em;
}

.h1-page-bis {
  color: #365644;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center; /* Centrage vertical */
  justify-content: flex-start;
  padding-left: 20px;
}

.Room_nb-space {
  padding-right: 10px;
}

.h2-page {
  color: #365644;
  font-family: Montserrat;
  padding-left: 20px;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center; /* Centrage vertical */
  justify-content: flex-start;
  margin-top: 1em;
  margin-bottom: 1em;
}

/* StatCard.css */
.stat-card {
  background-color: #365644; /* Utilisez la couleur de votre choix */
  color: white; /* Couleur par défaut pour le texte */
  padding: 20px;
  border-radius: 20px; /* Ajustez en fonction de la courbure que vous souhaitez */
  position: relative; /* Pour positionner l'icône absolument si nécessaire */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centre verticalement le contenu dans le flex container */
  align-items: center; /* Centre horizontalement le contenu dans le flex container */
  position: relative; /* Pour positionner absolument l'icône */
  width: 300px; /* Ou la largeur de votre choix */
  box-shadow: 15px 15px 0px rgba(4, 86, 68, 0.15);
  margin: 1em;
}

.stat-card-simple {
  background-color: #365644; /* Utilisez la couleur de votre choix */
  color: white; /* Couleur par défaut pour le texte */
  padding: 20px;
  border-radius: 20px; /* Ajustez en fonction de la courbure que vous souhaitez */
  position: relative; /* Pour positionner l'icône absolument si nécessaire */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centre verticalement le contenu dans le flex container */
  align-items: center; /* Centre horizontalement le contenu dans le flex container */
  position: relative; /* Pour positionner absolument l'icône */
  width: 300px; /* Ou la largeur de votre choix */
  box-shadow: 15px 15px 0px rgba(4, 86, 68, 0.15);
  margin: 0.5em;
}
.stat-number {
  font-size: 43px; /* Ajustez en fonction de la taille souhaitée */
  font-weight: bold; /* Pour le gras */
}

.stat-text1 {
  font-size: 23px; /* Ajustez en fonction de la taille souhaitée */
  font-weight: bold; /* Pour le gras */
}
.stat-text2 {
  font-size: 1em;
  line-height: 1em;
}

.stat-icon {
  width: 50px; /* Ajustez en fonction de la taille de l'icône */
  height: 50px; /* Ajustez en fonction de la taille de l'icône */
  position: absolute; /* Positionnez l'icône absolument si nécessaire */
  top: 50%;
  right: 20px;
  transform: translateY;
}

.logo-image-2 {
  /* Si vous voulez une taille spécifique pour le logo SVG */
  background-color: #f7eede;
  justify-content: center;
  align-items: center;
  border-radius: 9%; /* Arrondir les coins pour faire un cercle ou une forme ovale */
  width: 20px; /* Largeur du logo, ajustez selon vos besoins */
  height: 20px !important; /* Hauteur du logo, ajustez selon vos besoins */
  padding: 2px 5px 2px 5px;
  height: auto;
  position: absolute;
  top: 15px; /* Ajustez selon votre design pour positionner en haut */
  right: 15px; /* Ajustez selon votre design pour positionner à droite */
}
/* Ajoutez des styles supplémentaires pour les hover et les clics si nécessaire */

img.Logo-svg-2 {
  width: 100%;
  margin-top: -10px;
}

.col_dashboard_number {
  margin-top: 1em;
  padding-left: 1em;
  margin-bottom: 2em;
  display: flex; /* Utilisez Flexbox pour aligner les enfants horizontalement */
  justify-content: space-between; /* Espacement égal entre les cartes */
  align-items: center; /* Alignement vertical au centre */
  gap: 20px; /* Espace entre les cartes si vous souhaitez un espacement fixe */
}

.Dashboard-part-2 {
  display: flex;
  justify-content: space-between; /* Ajoute de l'espace entre les éléments */
  align-items: flex-start; /* Alignez les éléments en haut */
  padding: 20px 0px; /* Ajoutez du padding selon vos besoins */
  padding-left: 20px; /* Ajoutez du padding selon vos besoins */
  gap: 6.7%; /* Espace entre les éléments s'ils sont empilés */
  margin-top: 2em;
}
.Dashboard-part-2-bis {
  display: flex;
  justify-content: space-between; /* Ajoute de l'espace entre les éléments */
  align-items: flex-start; /* Alignez les éléments en haut */
  padding-left: 20px; /* Ajoutez du padding selon vos besoins */
  gap: 20px; /* Espace entre les éléments s'ils sont empilés */
  margin-top: 2em;
}
.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em; /* Ajoutez une marge en bas pour séparer du graphique */
}
.Chart_dashboard {
  flex: 2; /* Prend deux fois plus d'espace que le composant Donation */
  background-color: #f8f8f8; /* Couleur de fond pour le composant du graphique */
  border-radius: 10px; /* Arrondit les coins */
  padding: 20px; /* Ajoute du padding interne */
  /*box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Ajoute une ombre subtile */
  height: 400px;
}

.Donation {
  flex: 1; /* Prend moins d'espace que le composant du graphique */
  background-color: #f8f8f8; /* Couleur de fond pour le composant des dons */
  border-radius: 10px; /* Arrondit les coins */
  padding: 20px; /* Ajoute du padding interne */
  /*box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Ajoute une ombre subtile */
  display: flex; /* Utilise Flexbox pour positionner les StatCard */
  flex-direction: column; /* Empile les StatCard verticalement */
  gap: 10px; /* Espace entre les StatCard */
  height: 400px;
}

.Title_graph {
  color: #365644;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: left;
}

.more-button {
  background-color: #346840; /* Couleur verte foncée */
  color: white; /* Texte en blanc */
  border: none; /* Pas de bordure */
  border-radius: 20px; /* Bords arrondis */
  padding: 10px 20px; /* Padding intérieur */
  font-size: 16px; /* Taille de la police */
  cursor: pointer; /* Curseur en forme de pointeur pour indiquer qu'il s'agit d'un bouton */
  display: flex; /* Utilisé pour aligner le texte et la flèche */
  align-items: center; /* Centrer les éléments verticalement */
  justify-content: center; /* Centrer les éléments horizontalement */
  gap: 10px; /* Espace entre le texte et l'icône */
}

.more-button-tags {
  background-color: #365645; /* Couleur verte foncée */
  color: white; /* Texte en blanc */
  border: none; /* Pas de bordure */
  border-radius: 20px; /* Bords arrondis */
  padding: 10px 20px; /* Padding intérieur */
  font-size: 16px; /* Taille de la police */
  cursor: pointer; /* Curseur en forme de pointeur pour indiquer qu'il s'agit d'un bouton */
  display: flex; /* Utilisé pour aligner le texte et la flèche */
  align-items: center; /* Centrer les éléments verticalement */
  justify-content: center; /* Centrer les éléments horizontalement */
  gap: 10px; /* Espace entre le texte et l'icône */
}

.more-button:active,
.more-button.active {
  background-color: #7faa6d;
  color: #ffff;
}

.more-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px; /* Taille du cercle */
  height: 24px; /* Taille du cercle */
  border-radius: 50%; /* Rendre la forme ronde */
  background-color: #f7eede; /* Couleur beige pour l'arrière-plan */
  margin-left: 10px; /* Ajouter un peu d'espace entre le texte et la flèche */
  transition: transform 0.3s ease; /* Animation pour le mouvement de la flèche */
  color: #365644;
  font-weight: 1000;
  font-size: 20px;
}
.more-arrow-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Taille du cercle */
  height: 40px; /* Taille du cercle */
  border-radius: 50%; /* Rendre la forme ronde */
  background-color: #f7eede; /* Couleur beige pour l'arrière-plan */
  margin-left: 10px; /* Ajouter un peu d'espace entre le texte et la flèche */
  transition: transform 0.3s ease; /* Animation pour le mouvement de la flèche */
  color: #365644;
  font-weight: 1000;
  font-size: 1.7em;
  margin-right: -6px;
}
.linkHotels span:hover {
  background-color: #cac8c5;
  transition: 0.5s;
  /* //background-color: #f7eede; */
}
.more-button-2 {
  background-color: #346840; /* Couleur verte foncée */
  color: white; /* Texte en blanc */
  border: none; /* Pas de bordure */
  border-radius: 20px; /* Bords arrondis */
  padding: 5px 5px; /* Padding intérieur */
  font-size: 16px; /* Taille de la police */
  cursor: pointer; /* Curseur en forme de pointeur pour indiquer qu'il s'agit d'un bouton */
  display: flex; /* Utilisé pour aligner le texte et la flèche */
  align-items: center; /* Centrer les éléments verticalement */
  justify-content: center; /* Centrer les éléments horizontalement */
  gap: 10px; /* Espace entre le texte et l'icône */
}
.more-button-3 {
  background-color: #346840; /* Couleur verte foncée */
  color: white; /* Texte en blanc */
  border: none; /* Pas de bordure */
  border-radius: 20px; /* Bords arrondis */
  padding: 5px 5px; /* Padding intérieur */
  font-size: 16px; /* Taille de la police */
  cursor: pointer; /* Curseur en forme de pointeur pour indiquer qu'il s'agit d'un bouton */
  display: flex; /* Utilisé pour aligner le texte et la flèche */
  align-items: center; /* Centrer les éléments verticalement */
  justify-content: center; /* Centrer les éléments horizontalement */
  gap: 10px; /* Espace entre le texte et l'icône */
}
.more-button-3 :hover {
  transform: translateX(-10px);
  transition: 0.4s;
}
.more-arrow-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px; /* Taille du cercle */
  height: 24px; /* Taille du cercle */
  border-radius: 50%; /* Rendre la forme ronde */
  margin-left: px; /* Ajouter un peu d'espace entre le texte et la flèche */
  transition: transform 0.3s ease; /* Animation pour le mouvement de la flèche */
  background-color: #346840; /* Couleur verte foncée */
  background: none;
  font-weight: 1000;
  font-size: 30px;
}

more-arrow-2-bis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px; /* Taille du cercle */
  height: 24px; /* Taille du cercle */
  border-radius: 50%; /* Rendre la forme ronde */
  margin-left: px; /* Ajouter un peu d'espace entre le texte et la flèche */
  transition: transform 0.3s ease; /* Animation pour le mouvement de la flèche */
  color: #346840; /* Couleur verte foncée */
  background: none;
  font-weight: 1000;
  font-size: 30px;
}

.more-button:hover .more-arrow {
  transform: translateX(
    5px
  ); /* Déplace la flèche vers la droite quand on survole le bouton */
}

.more-button-link {
  text-decoration: none; /* Supprime le soulignement des liens */
  color: inherit; /* Hérite de la couleur de texte parente */
}

.certificate-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #9099ac; /* Couleur de fond de la carte */
  border-radius: 10px; /* Bords arrondis */
  padding: 10px 20px; /* Padding intérieur */
  font-size: 16px; /* Taille de la police */
  color: #f7eede; /* Couleur du texte */
  text-align: left;
  margin-bottom: 1em;
}

.certificate-info {
  flex-grow: 1;
}

.certificate-title {
  font-weight: bold; /* Rendre le titre du certificat en gras */
}

.certificate-date {
  font-size: 0.9em; /* Taille de police plus petite pour la date */
}

.download-button {
  background: #f7eede;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.download-icon {
  background-color: #d9d9d9; /* Couleur de fond de l'icône de téléchargement */
  border-radius: 50%; /* Rendre l'icône circulaire */
  padding: 10px; /* Espace autour de l'icône */
  transition: background-color 0.2s ease; /* Transition pour l'effet hover */
}

.download-button:hover .download-icon {
  background-color: #bfbfbf; /* Changement de couleur au survol */
}

.Dashboard {
  overflow: hidden; /* Cela empêchera le défilement sur l'ensemble de la page */
  padding-left: 10px;
  margin-top: 2em;
}
.economies {
  padding-left: 10px;
  margin-top: 2em;
}
.Donation_global {
  padding-left: 10px;
  margin-top: 2em;
}
.Customer_xp {
  padding-left: 10px;
  margin-top: 2em;
}
.rooms {
  padding-left: 10px;
  margin-top: 2em;
}
.consommation {
  padding-left: 10px;
  margin-top: 2em;
}
.room_overview {
  padding-left: 10px;
  margin-top: 2em;
}
.stat_simple {
  margin-left: 1em;
  flex: 1; /* Prend moins d'espace que le composant du graphique */
  display: flex; /* Utilise Flexbox pour positionner les StatCard */
  flex-direction: column; /* Empile les StatCard verticalement */
  height: 400px;
  justify-content: space-between;
  align-items: flex-end;
}

.stat-small-card {
  background-color: #f8f8f8;
  border-radius: 10px; /* Bords arrondis */
  padding: 20px; /* Espacement à l'intérieur de la carte */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Ombre légère pour un effet de profondeur */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligner le contenu à gauche */
  width: 400px;
}

.stat-small-title {
  font-size: 16px; /* Taille de la police pour le titre */
  color: #365644; /* Couleur du texte pour le titre */
  margin-bottom: 0.5em; /* Espacement entre le titre et la valeur */
}

.stat-small-value {
  font-size: 24px; /* Taille de la police pour la valeur */
  font-weight: bold; /* Rendre la valeur en gras */
  color: #346840; /* Couleur du texte pour la valeur */
}

.stat-small-unit {
  font-size: 18px; /* Taille de la police pour l'unité */
}

.date-picker {
  display: flex; /* Utilisez flex pour aligner les éléments inline */
  align-items: center; /* Centre les éléments verticalement */
  gap: 0.5em; /* Espace entre les éléments */
  color: #365644;
  text-align: right;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  padding-left: 1em;
}

.date-picker input[type="date"] {
  border: none; /* Pas de bordure */
  background-color: transparent; /* Arrière-plan transparent */
  color: #365644;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer; /* Style de curseur pour indiquer qu'il s'agit d'un champ cliquable */
}

/* Style personnalisé pour les éléments de saisie de date lorsque le navigateur ne prend pas en charge le style natif */
.date-picker input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(
    50%
  ); /* Inverser les couleurs de l'icône pour une meilleure visibilité */
  cursor: pointer;
}

.Bouton_titre {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  padding-top: 2em;
  padding-left: 20px;
}

.cntContener {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 2em;
  margin-left: 3em;
  border-radius: 20px;
  margin-bottom: 1.5em;
  margin-top: 2em;
  transition: 0.4s;
  background: #90b878;
  color: #f7eede; /* Ajustez en fonction de l'espacement nécessaire */
}

.room-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 2em;
  margin-left: 20px;
  background: rgba(200, 214, 240, 0.15);
  color: #365644;
  border-radius: 20px;
  margin-bottom: 1.5em; /* Ajustez en fonction de l'espacement nécessaire */
}
.room-card:hover {
  /* cursor: pointer; */
  transition: 0.4s;
  background: #90b878;
  color: #f7eede;
}

.room-card-2 {
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 2em;
  margin-left: 20px;
  background: rgba(200, 214, 240, 0.15);
  color: #365644;
  border-radius: 20px;
  margin-bottom: 1.5em; /* Ajustez en fonction de l'espacement nécessaire */
}
.room-card-2:hover {
  transition: 0.4s;
  background: #90b878;
  color: #f7eede;
}

.room-card:hover .more-arrow-2 {
  transform: translateX(
    10px
  ); /* Déplace la flèche vers la droite quand on survole le bouton */
}

.room-card:hover .room-icon {
  filter: brightness(0) saturate(100%) invert(89%) sepia(26%) saturate(121%)
    hue-rotate(352deg) brightness(104%) contrast(94%);
}
.room-card-2:hover .room-icon {
  filter: brightness(0) saturate(100%) invert(89%) sepia(26%) saturate(121%)
    hue-rotate(352deg) brightness(104%) contrast(94%);
}

.room-card-2:hover img.imgdownload {
  filter: brightness(0) saturate(100%) invert(89%) sepia(26%) saturate(121%)
    hue-rotate(352deg) brightness(104%) contrast(94%);
}

.TopRoomCardText {
  color: inherit;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.RoomcardElem {
  margin: 0 1em;
}
.BottomRoomCardText {
  color: inherit;
  font-family: Montserrat;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.room-details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.room-info {
  margin-bottom: 5px; /* ou selon l'espacement souhaité */
}
.room-icon {
  margin: 1em; /* ou selon l'espacement souhaité */
}
.room-info span {
  display: block;
  /* Ajoutez des styles supplémentaires si nécessaire */
}

.room-gains {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.room-gains span {
  display: block;
  /* Ajoutez des styles supplémentaires si nécessaire */
}

.room-link {
  color: inherit;
  text-decoration: none;
  /* Ajoutez des styles supplémentaires si nécessaire */
}

.search-and-select {
  display: flex; /* Aligner les éléments horizontalement */
  gap: 10px; /* Espace entre les éléments */
  margin-bottom: 2em;
  padding-left: 20px;
  width: 35%;
}

.search-input,
.select-input {
  border-radius: 18px;
  background: rgba(3, 3, 4, 0.15);
  border: none;
  padding: 5px 15px; /* Padding intérieur pour le texte */
  color: #365644;
  font-family: Montserrat;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.search-input {
  flex-grow: 1; /* Prendre l'espace disponible */
  width: 30%;
  padding: 1em 15px;
}

.select-input {
  cursor: pointer; /* Indique que l'élément est cliquable */
  background-color: #f7eede; /* Fond beige */
  color: #365644; /* Couleur du texte */
}

.Partie2-donation {
  margin-top: 2em;
}

.MadeBlueFoundation {
  background-color: #f7f7f7; /* Couleur de fond de la carte */
  border-radius: 8px; /* Bords arrondis de la carte */
  padding: 20px; /* Espacement interne de la carte */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre portée de la carte */
  display: flex; /* Utiliser flexbox pour positionner le contenu */
  justify-content: space-between; /* Répartir l'espace entre le texte et le bouton */
  align-items: flex-start; /* Centrer les éléments verticalement */
  flex-direction: column;
}

.Foundation-title {
  font-size: 24px; /* Taille du texte pour le titre */
  color: #365644; /* Couleur du texte pour le titre */
  margin-bottom: 10px; /* Espacement sous le titre */
  margin-left: 1em;
  text-align: left;
}
.Description_Madeblue {
  display: flex; /* Utiliser flexbox pour positionner le contenu */
  justify-content: space-between; /* Répartir l'espace entre le texte et le bouton */
  align-items: center; /* Centrer les éléments verticalement */
  flex-direction: row;
}

.MadeBlueText {
  color: #626262; /* Couleur du texte pour le paragraphe */
  flex: 4; /* Le texte prend tout l'espace disponible */
  padding: 10px 1.5em;
  padding-right: 3em;
  text-align: left;
}

.MadeBlueMore {
  border-radius: 30px;
  background: rgba(144, 184, 120, 0.9);
  flex: 1;
  border: none; /* Aucune bordure pour le bouton */
  border-radius: 20px; /* Bords arrondis du bouton */
  padding: 10px 20px; /* Espacement interne du bouton */
  cursor: pointer; /* Apparence du curseur en survol du bouton */
  transition: background-color 0.3s; /* Transition pour l'effet de survol */
  color: #f7eede;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.card-button:hover {
  background-color: #2e5c4b; /* Changement de couleur de fond lors du survol */
}
.breadcrumb {
  font-family: "Montserrat"; /* You can change the font-family according to your design */
  font-size: 16px; /* Adjust the font size as needed */
  color: #365644; /* This is a dark grey color, similar to what's shown in the image */
  display: flex;
  align-items: center;
  padding-left: 20px !important;
}

.breadcrumb a {
  text-decoration: none;
  color: #365644; /* Same color as the text for consistency */
  margin: 0 5px; /* Adjust spacing around the links as needed */
}

.breadcrumb a:hover {
  text-decoration: underline;
  color: #365644; /* Optional: adds underline on hover */
  font-weight: bold;
  cursor: pointer;
}

/* If you want to use a special character or an image for the separator */

.last_elem {
  font-weight: bold !important; /* Optional: makes the last part of the breadcrumb bold */
  color: #365644; /* Optional: adds underline on hover */
  font-family: "Montserrat"; /* You can change the font-family according to your design */
  color: #365644;
  margin: 0 5px; /* Adjust spacing around the links as needed */
}

.version {
  color: #dce0c4;
  margin-top: -0.1px;
  opacity: 0.5;
}

.Room_dateTitle {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}
.button-container {
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  gap: 10px; /* Espacement entre les boutons */
}

.filterTags {
  display: flex;
  margin-bottom: 1em;
  margin-left: 20px;
  align-items: center;
  justify-content: flex-start;
}

.button-container-tags {
  margin-right: 20px;
  display: inline-flex;
  border: #3a5248 2px solid;
  border-radius: 8px;
  padding: 2px;
}

.ok {
  padding: 5px 10px;
  font-size: 14px;
  color: #365645;
  background-color: #f7f9fd;
  border: 5px solid #365644;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 140px;
  font-family: "Arial", sans-serif;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  margin-left: 0.5em;
}

.ok:hover {
  color: white;
  background-color: #90b878;
}

.ok:focus {
  outline: none;
}

.more-button-tags-bis {
  font-weight: bold;
  margin-right: 15px;
  font-size: 16px;
  color: #365645;
}

.review-card {
  max-width: 400px;
  padding: 20px;
  margin-left: 20px;
  margin-top: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  color: #3a5248;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  display: block;
}

/* Styling for the name and rating */
.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
  color: #3a5248;
}

/* Date and platform styling */
.review-date {
  font-size: 0.9em;
  color: #6e7e73;
  font-style: italic;
  margin-top: 5px;
  text-align: left;
}

/* Review text styling */
.review-content {
  margin-top: 15px;
  font-size: 0.8em;
  line-height: 1.5;
  color: #3a5248;
  text-align: left;
}

/* Hover effect for the card */
.review-card:hover {
  background-color: #e8e8e8;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.recap_container {
  text-align: left;
  margin-left: 20px;
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  background-color: #e8e8e8;
  border-radius: 10px;
  max-width: 800px;
  font-family: Arial, sans-serif;
  color: #3a5248;
}
.recap_textSection {
  flex: 1;
  margin-right: 20px;
}
.recap_title {
  font-size: 1.5em;
  font-weight: bold;
  color: #3a5248;
}
.recap_objective {
  margin-top: 3em;
}
.recap_defaultObjective {
  margin-bottom: 1em;
  font-size: 2.5em;
  font-weight: bold;
  color: #3a5248;
}
.recap_proposedObjectives {
  margin-top: 10px;
}
.recap_objectiveValues {
  font-size: 2.3em;
  font-weight: bold;
  color: #3a5248;
}
.recap_resources {
  margin-top: 15px;
  max-width: 50%;
}
.recap_imageSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40%;
}
.recap_image {
  width: 100%;
  border-radius: 5px;
}
.recap_imageCaption {
  margin-top: 10px;
  font-size: 0.9em;
  color: #3a5248;
}

button.icon-button-3.langBtn.btn.btn-primary {
  font-weight: bold;
  background: #656565;
  color: white;
}

.chart-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10em;
}

.chart-loader-text {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.table-container {
  width: 98%;
  margin: 20px auto;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  color: #365644;
}

.column-large {
    width: 15%;
}

.table-header {
  background: #f8f8f8;
  color: #fff;
}

.table-title {
  padding: 12px 16px;
  font-weight: 700;
  text-align: center;
  font-size: 14px;
  color: #365645;
}

.table-cell {
  padding: 12px 16px;
  font-size: 14px;
  text-align: center;
}

.regularization-text {
  font-size: 14px;
  color: #6b7280;
  margin-top: 4px;
}